<template>
  <div class="create-work-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item>創建作品</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="workForm"
        :model="work_form"
        :rules="work_rules"
        label-position="top"
        v-loading.fullscreen.lock="loading"
        element-loading-text="上傳中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form-item label="作品啟用狀態" prop="active">
          <el-radio-group v-model="work_form.active" size="small">
            <el-radio :label="true" :border="true">啟用</el-radio>
            <el-radio :label="false" :border="true">停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="中文作品名稱" prop="title_zh">
          <el-input
            v-model="work_form.title_zh"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文作品名稱" prop="title_en">
          <el-input
            v-model="work_form.title_en"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="中文作品大小" prop="dimension_zh">
          <el-input
            v-model="work_form.dimension_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文作品大小" prop="dimension_en">
          <el-input
            v-model="work_form.dimension_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="作品展覽類別" prop="category_id">
          <el-select v-model="work_form.category_id">
            <el-option
              v-for="{ category_id, name_zh, name_en } in categories"
              :key="category_id"
              :value="category_id"
              :label="name_zh"
              style="width: 300px"
            >
              <span
                style="float: left; width: 120px; display: block"
                class="text-overflow"
                >{{ name_zh }}</span
              >
              <span
                style="
                  float: right;
                  color: #8492a6;
                  font-size: 13px;
                  width: 120px;
                  display: block;
                  text-align: right;
                "
                class="text-overflow"
                >{{ name_en }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="時間場次" prop="session">
          <div
            v-for="(s, index) in work_form.session"
            :key="index"
            style="margin: 8px 0"
          >
            <el-date-picker
              v-model="s.start"
              type="datetime"
              placeholder="請選擇"
            >
            </el-date-picker>

            <span style="display: inline-block; margin: 0 6px">至</span>

            <el-date-picker
              v-model="s.end"
              type="datetime"
              placeholder="請選擇"
            >
            </el-date-picker>

            <template v-if="show_additional">
              <el-button
                icon="el-icon-circle-plus-outline"
                style="color: var(--success-color); margin: 0 3px 0 12px"
                @click="work_form.session.push({ start: '', end: '' })"
                type="text"
              ></el-button>
              <el-button
                icon="el-icon-remove-outline"
                type="text"
                style="color: var(--danger-color); margin: 0 3px 0 12px"
                @click="handleDeleteSession(index)"
              ></el-button>
            </template>
          </div>
        </el-form-item>

        <el-form-item label="作品種類" prop="type">
          <el-select v-model="work_form.type">
            <el-option value="" label="未指定"></el-option>
            <el-option
              v-for="t in types"
              :key="t"
              :value="t"
              :label="t"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="作品年份" prop="year">
          <el-date-picker
            v-model="work_form.year"
            type="year"
            placeholder="請選擇年份"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="作品結束年份" prop="end_year">
          <el-date-picker
            v-model="work_form.end_year"
            type="year"
            placeholder="請選擇年份"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="展區位置" prop="location_id">
          <el-select v-model="work_form.location" style="width: 300px">
            <el-option
              v-for="{ work_location_id, name_zh, name_en } in locations"
              :key="work_location_id"
              :value="work_location_id"
              :label="name_zh"
            >
              <span
                style="float: left; width: 120px; display: block"
                class="text-overflow"
                >{{ name_zh }}</span
              >
              <span
                style="
                  float: right;
                  color: #8492a6;
                  font-size: 13px;
                  width: 120px;
                  display: block;
                  text-align: right;
                "
                class="text-overflow"
                >{{ name_en }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="中文媒材">
          <el-input v-model="work_form.material_zh"></el-input>
        </el-form-item>

        <el-form-item label="英文媒材">
          <el-input v-model="work_form.material_en"></el-input>
        </el-form-item>

        <el-form-item label="中文作品理念" prop="proposal_zh">
          <div ref="js-editor-proposal_zh"></div>
        </el-form-item>

        <el-form-item label="英文作品理念" prop="proposal_en">
          <div ref="js-editor-proposal_en"></div>
        </el-form-item>

        <el-form-item prop="interactive_description_zh">
          <div slot="label">
            中文互動文字說明
            <span
              style="
                color: var(--danger-color);
                font-size: 12px;
                margin-left: 4px;
              "
              >*多組請以 ｜ 隔開</span
            >
          </div>
          <el-input
            type="textarea"
            v-model="work_form.interactive_description_zh"
            :rows="8"
          ></el-input>
        </el-form-item>

        <el-form-item prop="interactive_description_en">
          <div slot="label">
            英文互動文字說明
            <span
              style="
                color: var(--danger-color);
                font-size: 12px;
                margin-left: 4px;
              "
              >*多組請以 ｜ 隔開</span
            >
          </div>
          <el-input
            type="textarea"
            v-model="work_form.interactive_description_en"
            :rows="8"
          ></el-input>
        </el-form-item>

        <el-form-item label="中文備註" prop="note_zh">
          <el-input
            type="textarea"
            v-model="work_form.note_zh"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文備註" prop="note_zh">
          <el-input
            type="textarea"
            v-model="work_form.note_en"
            :rows="3"
          ></el-input>
        </el-form-item>

        <el-divider></el-divider>

        <template v-for="val in [1, 2, 3, 4, 5]">
          <el-row :key="val">
            <el-col :span="8">
              <el-form-item :label="`作品圖片${val}`">
                <el-upload
                  :ref="`upload-${val}`"
                  class="work-uploader"
                  action=""
                  :show-file-list="false"
                  :before-upload="handlePhotoBeforeUpload"
                  :http-request="handlePhotoUpload"
                  @mousedown.native="handlePhotoUploadCurrent(`upload-${val}`)"
                  accept=".jpeg,.jpg,.png"
                >
                  <img
                    v-if="imageUrls[`url${val}`]"
                    :src="imageUrls[`url${val}`]"
                    alt="work"
                    class="work-image"
                  />
                  <i v-else class="el-icon-plus work-uploader-icon"></i>
                </el-upload>
                <el-button
                  type="danger"
                  size="small"
                  @click="handlePhotoUploadClear(`upload-${val}`)"
                  >清除圖片
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="圖片中文描述">
                <el-input
                  type="textarea"
                  :rows="4"
                  v-model="work_form[`photo_${val}_d_zh`]"
                ></el-input>
              </el-form-item>
              <el-form-item label="圖片英文描述">
                <el-input
                  type="textarea"
                  :rows="4"
                  v-model="work_form[`photo_${val}_d_en`]"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-divider></el-divider>

        <el-form-item prop="file_url">
          <span slot="label"
            >影片連結 <span class="tip">(*若多組請使用 , 隔開)</span></span
          >
          <el-input
            v-model="work_form.file_url"
            placeholder="請輸入 youtube 連結"
          ></el-input>
        </el-form-item>

        <el-form-item label="作品線上呈現檔案" prop="file">
          <el-upload
            class="upload-demo"
            ref="js-upload"
            action=""
            :http-request="handleFileUpload"
            :on-change="handleFileChange"
            :on-remove="handleFileRemove"
            :file-list="file_list"
            accept=".zip"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上傳檔案
            </el-button>

            <el-button
              size="small"
              type="warning"
              style="margin-left: 12px"
              :disabled="file_list.length === 0"
              @click="handleFileRemove"
              >清除本地端檔案</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <upload-box></upload-box>
  </div>
</template>

<script>
import { getWorkLocations } from "@/api/work-location";
import { createWork, uploadWorkFile, uploadWorkPhoto } from "@/api/work";
import { getCategories } from "@/api/category";
import types from "@/views/work/main/utils/types";
import UploadBox from "@/components/UploadBox";
import E from "wangeditor";

export default {
  name: "CreateWork",
  components: { UploadBox },
  data() {
    const validateSession = (_rule, _value, callback) => {
      for (let i = 0; i < this.work_form.session.length; i++) {
        if (
          (this.work_form.session[i].start && !this.work_form.session[i].end) ||
          (!this.work_form.session[i].start && this.work_form.session[i].end)
        ) {
          callback(new Error("請將場次選填完成"));
          break;
        }
      }

      callback();
    };

    return {
      work_form: {
        title_zh: "",
        title_en: "",
        proposal_zh: "",
        proposal_en: "",
        interactive_description_zh: "",
        interactive_description_en: "",
        dimension_zh: "",
        dimension_en: "",
        active: true,
        type: "",
        year: "",
        end_year: "",
        material_zh: "",
        material_en: "",
        category_id: "",
        session: [{ start: "", end: "" }],
        location_id: "",
        photo_1: null,
        photo_1_d_zh: "",
        photo_1_d_en: "",
        photo_2: null,
        photo_2_d_zh: "",
        photo_2_d_en: "",
        photo_3: null,
        photo_3_d_zh: "",
        photo_3_d_en: "",
        photo_4: null,
        photo_4_d_zh: "",
        photo_4_d_en: "",
        photo_5: null,
        photo_5_d_zh: "",
        photo_5_d_en: "",
        note_zh: "",
        note_en: "",
        file_url: "",
        file: null,
      },
      work_rules: {
        title_zh: [
          { required: true, message: "請輸入名稱", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        title_en: [
          { required: true, message: "請輸入名稱", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        proposal_zh: [
          { required: true, message: "請輸入理念", trigger: "blur" },
        ],
        proposal_en: [
          { required: true, message: "請輸入理念", trigger: "blur" },
        ],
        active: [
          { required: true, message: "請輸入是否啟用", trigger: "blur" },
        ],
        dimension_zh: [{ max: 64, message: "長度過長", trigger: "blur" }],
        dimension_en: [{ max: 64, message: "長度過長", trigger: "blur" }],
        year: [{ required: true, message: "請選擇年代", trigger: "change" }],
        category_id: [
          { required: true, message: "請選擇地點", trigger: "change" },
        ],
        session: [{ validator: validateSession, trigger: "change" }],
        location_id: [
          { required: true, message: "請選擇地點", trigger: "change" },
        ],
      },
      imageUrls: {
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
      },
      current_upload_ref: "",
      locations: [],
      categories: [],
      show_additional: false,
      types,
      loading: false,
      file_list: [],
    };
  },
  watch: {
    "work_form.category_id": function (val) {
      const category = this.categories.find(
        ({ category_id }) => category_id === val
      );

      this.show_additional =
        category?.name_en.toLowerCase().includes("fvl") ||
        category?.name_en.toLowerCase().includes("future vision lab") ||
        false;

      // remove session when not include fvl category
      if (!this.show_additional) {
        this.work_form.session = [{ start: "", end: "" }];
      }
    },
  },
  async created() {
    await this.handleGetCategories();
    await this.handleGetWorkLocations();
  },
  mounted() {
    this.handleInitEditor();
  },
  methods: {
    async handleGetCategories() {
      this.categories = await getCategories();

      if (this.categories.length === 0) {
        await this.$alert("你尚未新增類別，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "CreateCategory" });
          },
        });
      } else {
        this.work_form.category_id = this.categories[0].category_id;
      }
    },
    async handleGetWorkLocations() {
      this.locations = await getWorkLocations();
      if (this.locations.length === 0) {
        await this.$alert("你尚未新增任何展區，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "CreateWorkLocation" });
          },
        });
      } else {
        this.work_form.location_id = this.locations[0].work_location_id;
      }
    },
    handlePhotoBeforeUpload(file) {
      const imageType = ["image/jpeg", "image/jpg", "image/png"];

      if (imageType.indexOf(file.type) === -1) {
        this.$message.error("請上傳jpg, jpeg, png的格式");
        return false;
      }

      const isLt3M = file.size / 1024 / 1024 < 3;

      if (!isLt3M) {
        this.$message({
          type: "error",
          message: "請上傳小於3MB的圖片",
        });

        return false;
      }

      return true;
    },
    handlePhotoUploadCurrent(ref) {
      if (ref in this.$refs) {
        this.current_upload_ref = ref;
      }
    },
    handlePhotoUpload(file) {
      if (this.current_upload_ref) {
        const index = this.current_upload_ref.split("-")[1];

        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.imageUrls[`url${index}`] = reader.result;
        });

        if (file) {
          reader.readAsDataURL(file.file);
          this.work_form[`photo_${index}`] = file.file;
        }
      }

      this.current_upload_ref = "";
    },
    handlePhotoUploadClear(ref) {
      const index = ref.split("-")[1];

      this.imageUrls[`url${index}`] = "";

      this.work_form[`photo_${index}`] = null;
    },
    handleFileUpload(file) {
      this.work_form.file = file.file;
    },
    handleFileChange(file) {
      this.file_list = [];
      this.file_list.push(file);
    },
    handleFileRemove() {
      this.file_list = [];
      this.work_form.file = null;
    },
    handleDeleteSession(index) {
      // prevent remove session default
      if (this.work_form.session.length === 1) return;

      this.work_form.session.splice(index, 1);
    },
    handleSubmit() {
      this.$refs.workForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            const {
              title_zh,
              title_en,
              dimension_zh,
              dimension_en,
              interactive_description_zh,
              interactive_description_en,
              active,
              category_id,
              session,
              type,
              year,
              end_year,
              location_id,
              material_en,
              material_zh,
              proposal_en,
              proposal_zh,
              note_en,
              note_zh,
              file_url,
              file,
              ...rest
            } = {
              ...this.work_form,
            };

            const { work_id } = await createWork({
              title_zh,
              title_en,
              dimension_zh,
              dimension_en,
              interactive_description_zh,
              interactive_description_en,
              active,
              category_id,
              session,
              type,
              year,
              end_year,
              location_id,
              material_en,
              material_zh,
              proposal_en,
              proposal_zh,
              note_en,
              note_zh,
              file_url,
            });

            const photoFormData = new FormData();

            Object.keys(rest).forEach((key) => {
              if (rest[key]) {
                photoFormData.append(key, rest[key]);
              }
            });

            await uploadWorkPhoto(work_id, photoFormData);

            if (file !== null) {
              const fileFormData = new FormData();

              fileFormData.append("file", file);

              await uploadWorkFile(work_id, fileFormData);
            }

            this.$message({
              type: "success",
              message: "創建成功",
            });

            await this.handleRedirect();
            this.loading = false;
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
        }
      });
    },
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith(["js-editor"])) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          editor.config.onchange = (html) => {
            this.work_form[key] = html;
          };

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];
          editor.create();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Work" });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-work-block {
  ::v-deep.work-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  ::v-deep.work-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  ::v-deep.work-image {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
