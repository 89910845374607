<template>
  <transition name="slide-fade">
    <div v-if="progress.length !== 0" id="js-upload-box" class="upload-box">
      <div class="top">上傳檔案中...</div>
      <div class="progress-bar-block">
        <div v-for="p in progress" :key="p.queue" class="progress-bar">
          <p class="title">檔案：{{ p.title }}</p>
          <div class="wrapper">
            <i class="el-icon-upload" aria-label="上傳" />
            <el-progress
              :percentage="p.progress"
              :format="format"
              :color="customColors"
            />
            <!--            <i-->
            <!--              aria-label="取消上傳"-->
            <!--              class="el-icon-circle-close cancel"-->
            <!--              @click="cancelTasks(p.queue)"-->
            <!--            />-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";

export default {
  name: "UploadBox",
  data() {
    return {
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#6f7ad3", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#5cb87a", percentage: 100 },
      ],
    };
  },
  computed: {
    progress() {
      return this.$store.getters.progress;
    },
    cancelProgress() {
      return this.$store.getters.cancelProgress;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.handleReload, false);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleReload);

    // Remove all promises and using setTimeout make notify shows correctly. Not folding together.
    this.cancelProgress.forEach((el) =>
      setTimeout(() => {
        el.cancel("檔案取消上傳");
        this.notify(el.queue);
      }, 400)
    );
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },
    cancelTasks(queue) {
      this.cancelProgress
        .find((el) => el.queue === queue)
        .cancel("檔案取消上傳");
      this.notify(queue);
    },
    notify(queue) {
      store
        .dispatch("upload/removeProgress", queue)
        .then(() => {
          this.$notify.error({
            title: "取消",
            message: "檔案取消上傳",
          });
        })
        .catch(console.error);
    },
    handleReload(e) {
      if (this.progress.length !== 0) {
        e.returnValue = "上傳中的檔案即將遺失，是否繼續";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upload-box {
  position: fixed;
  bottom: 36px;
  right: 36px;
  width: 365px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  z-index: 10000;

  .top {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 12px 0 12px 16px;
    background-color: #333333;
    color: #ffffff;
    font-size: 12px;
  }

  .progress-bar-block {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 12px 0;

    .progress-bar {
      .title {
        font-size: 12px;
        padding: 0 12px;
        margin-bottom: 4px;
        color: #606266;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .wrapper {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        i {
          font-size: 14px;
          line-height: 1;
          padding: 0 6px 0 12px;
          color: #dd6161;
        }
      }

      .el-progress {
        width: 100%;
      }

      .el-progress__text {
        font-size: 14px !important;
      }

      .cancel {
        cursor: pointer;
      }
    }
  }
}
</style>
